/* src/styles.css */

body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f7f7f7;
}

/* Header.css */
.logo-title-container {
  display: flex;
  align-items: center;
  /* Align items vertically in the center */
}

.logo {
  width: 150px;
  /* Adjust the width of the logo */
  height: 150px;
  /* Maintain aspect ratio */
  margin-right: 10px;
  border-radius: 50%;
  /* Add some space between the logo and the text */
}

@media (max-width: 480px) {
  .header-item .logo {
    width: 60px;
    height: 60px;
  }
  .header-item .title {
    font-size: 20px;
  }
}

.title {
  margin: 0;
  font-size: 40px;
  /* Remove default margin for the title */
}

.logo-container:hover .logo {
  transform: scale(1.1);
  /* Apply zoom effect on hover */
}

.title {
  color: #1d1601;
}

.link {
  text-decoration: none !important;
}

.clickable {
  cursor: pointer;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.header {
  display: grid;
  grid-template-columns: auto auto auto;
  color: #1d1601;
  padding: 10px;
  /* text-align: center; */
  background: #DA9D5B;
}

.header-item {
  text-align: center;
  align-self: center;
}

.footer {
  background-color: cornflowerblue;
  color: #fff;
  padding: 10px;
  text-align: center;
}

.home {
  text-align: center;
}

.main-title {
  margin: 20px 0;
  font-size: 24px;
}

.search-bar {
  margin-bottom: 20px;
}

.search-bar input {
  padding: 10px;
  margin-right: 10px;
  width: 60%;
}

.search-bar button {
  padding: 10px;
}

.search-results {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}

.search-result {
  cursor: pointer;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.search-result:hover {
  background-color: #f0f0f0;
}


.sloka-title {
  font-weight: bold;
  margin: 5px 0;
}

.meaning {
  margin: 5px 0;
}

.chapter {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.chapter h1 {
  color: #333;
}

.text {
  margin-bottom: 15px;
}

.sloka {
  font-style: italic;
  color: #1d1601 !important;
  font-weight: 600;
  white-space: pre-line;
  /* This property will preserve new lines */
}

.meaning,
.purport {
  font-size: 16px;
  line-height: 1.6;
  color: #4d4a4a;
  margin-top: 25px;
}

.chapter-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.chapter-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.chapter-title {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  text-decoration: none;
  color: #333;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.chapter-title:hover {
  transform: scale(1.05);
  background-color: coral;
  /* Change this color as per your preference */
}

.chapter {
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
}

.sloka-details {
  background-color: white;
}

.chapter-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.text-section {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 30px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.sloka-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.sloka {
  font-style: italic;
  color: #333;
}

.meaning {
  font-weight: bold;
  color: #555;
}

.word-to-word {
  margin-top: 20px;
  color: #141414;
}

.navigation-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.navigation-buttons button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.navigation-buttons button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Adjust button styles for your specific design */
.navigation-buttons .previous-button {
  background-color: #3498db;
  color: #fff;
  border: none;
}

.navigation-buttons .next-button {
  background-color: #27ae60;
  color: #fff;
  border: none;
}

.search-bar {
  display: flex;
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  /* Adjust the gap between buttons */
}

.button-container button {
  flex: 1;
  /* Each button takes equal width */
  max-width: 150px;
  /* Set maximum width for buttons */
}

@media (min-width: 768px) {

  /* Apply styles for screens wider than 768px (e.g., tablets) */
  .button-container {
    flex-direction: row;
  }

  .button-container button {
    max-width: none;
    /* Remove maximum width for buttons */
  }
}

.navigation-links {
  display: inline-block;
  align-items: center;
  margin-bottom: 20px;
  /* Adjust margin as needed */
}

.home-link,
.chapter-link,
.sloka-link {
  margin-right: 10px;
  /* Adjust spacing between links as needed */
  color: #333;
  /* Adjust link color */
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;
}

.home-link,
.chapter-link:hover {
  color: #170b5c;
  /* Adjust icon size as needed */
  font-weight: 600
}

.book-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;
  padding: 20px;
}

.book-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  background-color: #fff;

  img {
    border-radius: 5px 5px 0 0;
    width: 100%;
    height: 400px;
  }
}

.img-content {
  text-align: center;
}

.book-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.book-title {
  color: green;
}
.song-details-container {
  padding: 10px;
  max-width: 700px;
  margin: 20px auto;
  background-color: #f0f8ff; /* Light blue background */
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.song-title {
  font-size: 2.5em;
  color: #2c3e50; /* Darker shade */
  margin-bottom: 10px;
  text-align: center;
  font-family: 'Georgia', serif; /* Elegant font */
}

.song-source {
  font-size: 1.5em;
  color: #34495e; /* Slightly lighter shade */
  margin-bottom: 20px;
  text-align: center;
}

.song-details {
  background-color: #e8f5e9;
  border: 1px solid #d1e7dd;
  border-radius: 5px;
  font-family: Courier New, Courier, monospace;
  font-size: 1.2em;
  line-height: 1.6;
  overflow-wrap: break-word;
  padding: 7px;
  white-space: pre-wrap;
}

.song-not-found {
  color: #c0392b; /* Red for error messages */
  text-align: center;
  font-size: 1.2em;
  margin-top: 20px;
}
